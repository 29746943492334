import {  SET_MODAL_VIEW } from './ActionTypes';
import { getItem } from '../../utils/function';

const INITIAL_STATE = {
    modalView: getItem('modalView')
};
const ModalViewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MODAL_VIEW: {
      // setItem('modalView', action.payload);
      return {
        ...state,
        modalView: action.payload
      };
    }   
    default:
      return state;
  }
};

export default ModalViewReducer;
