import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import { baseUrl } from '../config';
import { axiosGet, axiosPost } from 'utils/ajax';
// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  changePassword = 'CHANGE_PASSWORD',
  Logout = 'LOGOUT',
  company = 'COMPANY'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    company: any;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.company]: {
    company: any;
  };
  [Types.Logout]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  company: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        company: null
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'COMPANY':
      return {
        ...state,
        isAuthenticated: true,
        company: action.payload.company
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const userData = window.localStorage.getItem('userData');
        if (
          accessToken
        ) {
          setSession(accessToken);
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: userData,
              company: null
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              company: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            company: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post(`${baseUrl}admin/login`, {
      email,
      password
    });
    const accessToken = response.headers['x-admin-token'];
    console.log("accessToken:::", accessToken)
    localStorage.setItem('userData', JSON.stringify(response.data));

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user: response.data
      }
    });
  };

  const changePassword = async (token: string, password: string) => {
    const response = axios.post(`${baseUrl}admin/updatePassword`, {
      token,
      password
    });

    return response;
  };
  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const setCompany = async (selectedCompany: any) => {
    dispatch({
      type: Types.company,
      payload: {
        company: selectedCompany
      }
    });
  };

  const resetPassword = (email: string) => {
    const response = axios.post(`${baseUrl}admin/reset-password`, {
      email,
      resetPasswordLink: `${window.location.origin}/verify`
    });
    return response;
  };
  // addSurvey calls
  const addSurvey = (values: any) => {
    const url = `${baseUrl}surveys/addSurvey/`
    const params = {
      surveyInfo: {
        ...values, 
        jobCategory: JSON.stringify(values.jobCategory), 
        jobTitle: JSON.stringify(values.jobTitle), 
        targeting_users: JSON.stringify(values.targeting_users), 
        effort: JSON.stringify(values.effort)
      }
    }
    return axiosPost(url, params)
  };
  // add company
  const addCompany = (values: any) => {
    const url = `${baseUrl}company/addCompany`
    const params = {
      name: values.name,
      country: values.country,
      industry: values.industry,
      status: values.status,
      acountType: values.acountType,
      imageLink: values.imageLink,
      subIndustry: values.subIndustry,
      subSubIndustry: values.subSubIndustry,
      endDate: values.endDate
    }
    return axiosPost(url, params)
  };
  // get companylist
  const GetCompaniesList = () => {
    const url = `${baseUrl}extras/companyList`
    const params = {}
    return axiosGet(url, params)
  };
  // get job type list
  const GetJobTypeList = (name: string = "") => {
    const url = `${baseUrl}extras/targetings/${name}`
    const params = {}
    return axiosGet(url, params)
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        addCompany,
        addSurvey,
        GetCompaniesList,
        setCompany,
        GetJobTypeList,
        changePassword,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
