import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import CompanyReducer from './reducers/companyReducer';
import ModalViewReducer from './reducers/modalViewReducer'
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  // add resucer here
  CompanyReducer:CompanyReducer,
  ModalViewReducer: ModalViewReducer,
});

export { rootPersistConfig, rootReducer };
