// material
// import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';
import LogoImage from '../assets/newLogo.png';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps): JSX.Element {
  // const theme = useTheme();

  return (
    <Box sx={{ ...sx }}>
      <img
        style={{
          height: '200px'
        }}
        src={LogoImage}
        alt="logo"
      />
    </Box>
  );
}
