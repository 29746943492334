import axios from 'axios';
import { BASE_FRONT_URL } from '../config';
import { SYSTEM_ERROR } from './constants';
// get survey list
const varToken = () => {
    const t = localStorage.getItem('accessToken');
    const pathname = window.location.href;
    if (t === "" || t === null || t === undefined) {
        if (!pathname.includes('auth/login') && !pathname.includes("/verify") && !pathname.includes("download")) {
            let redirect_url = BASE_FRONT_URL + "auth/login";
            window.location.href = redirect_url;
        };
        return "";
    } else {
        return t;
    }
}

const checkLoginRequired = (err) => {
    if (err && err.response && err.response.data && err.response.data.login_required === "1") {
        let redirect_url = BASE_FRONT_URL + "auth/login";
        console.log("redirect_url:::", redirect_url)
        window.location.href = redirect_url;
    }
}

///////////////////////////////////////////////////////////////////////////////////
export const axiosGet = (url, param, errorMessage = "") => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(url, {
                    headers: {
                        'x-admin-token': `Bearer ${varToken()}`
                    },
                    params: param
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    checkLoginRequired(err)
                    reject(err);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const axiosPost = (url, param, errorMessage = "", increaseTimeout) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(url, param, {
                    timeout: increaseTimeout ? 900000 : null,//set time out for 500 seconds
                    headers: {
                        'x-admin-token': `Bearer ${varToken()}`
                    }
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    checkLoginRequired(err)
                    reject(err);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const axiosPostMultipart = (url, param, errorMessage = "") => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .post(url, param, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'x-admin-token': `Bearer ${varToken()}`
                    },
                })
                .then((res) => {
                    //const { data } = res;
                    resolve(res);
                })
                .catch((err) => {
                    checkLoginRequired(err)
                    reject(err);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const axiosPut = (url, param, errorMessage = "122132321") => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(url, param, {
                    headers: {
                        'x-admin-token': `Bearer ${varToken()}`
                    }
                })
                .then((res) => {
                    //const { data } = res;
                    resolve(res);
                })
                .catch((err) => {
                    checkLoginRequired(err)
                    reject(err);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};
export const axiosDelete = (url, param, errorMessage = "") => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .delete(url, {
                    headers: {
                        'x-admin-token': `Bearer ${varToken()}`
                    },
                    data: param,
                })
                .then((res) => {
                    //const { data } = res;
                    resolve(res);
                })
                .catch((err) => {
                    checkLoginRequired(err)
                    reject(err);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

export const axiosPatch = (url, param, errorMessage = "122132321") => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .patch(url, param, {
                    headers: {
                        'x-admin-token': `Bearer ${varToken()}`
                    }
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    checkLoginRequired(err)
                    reject(err);
                });
        } catch (error) {
            reject(SYSTEM_ERROR);
        }
    });
};

