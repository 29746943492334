export const BASE_FRONT_URL = process.env.REACT_APP_BASE_FRONT_URL;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const s3Config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY
};
export const appId = process.env.REACT_APP_COMET_CHAT_APP_ID
export const apiKey = process.env.REACT_APP_COMET_CHAT_APP_API_KEY
export const authKey = process.env.REACT_APP_COMET_CHAT_APP_AUTH_KEY
export const appLinkQrCode = process.env.REACT_APP_QR_CODE_LINK
